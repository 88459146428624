import React from "react"

import Sponsors from "../components/sponsors"
import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo lang="de" title="Datenschutz" />
    <div className="content">
      <div id="privacy">
        <div className="container">
          <h1>Datenschutz</h1>
          <h2>I. Name und Anschrift des Verantwortlichen</h2>
          <p>
            Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und
            anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie
            sonstiger datenschutzrechtlicher Bestimmungen ist die:
          </p>
          <p>Milkroom Studios GmbH</p>
          <p>
            Bavariafilmplatz 7 - Gebäude 49
            <br />
            D-82031 Grünwald
            <br />
            Deutschland
          </p>
          <ul>
            <li>
              Tel.:&nbsp;
              <a href="tel:+498964981901">+49 89 64981901</a>
            </li>
            <li>
              E-Mail:&nbsp;
              <a href="mailto:info@milkroomstudios.com">
                info@milkroomstudios.com
              </a>
            </li>
            <li>
              Webseite:&nbsp;
              <a href="//www.milkroomstudios.com">milkroomstudios.com</a>
            </li>
          </ul>
          <h2>II. Name und Anschrift des Datenschutzbeauftragten</h2>
          <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
          <ul>
            <li>Michael Spitzer</li>
            <li>
              E-Mail:&nbsp;
              <a href="mailto:michael.spitzer@milkroomstudios.com">
                michael.spitzer@milkroomstudios.com
              </a>
            </li>
            <li>
              Webseite:&nbsp;
              <a href="//www.milkroomstudios.com">milkroomstudios.com</a>
            </li>
          </ul>
          <h2>III. Allgemeines zur Datenverarbeitung</h2>
          <h3>1. Umfang der Verarbeitung personenbezogener Daten</h3>
          <p>
            Die Betreiber von Bronzeon nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>
            Die Nutzung von Bronzeon wie auch der dazugehörigen Website ist in
            der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
            unseren Seiten personenbezogene Daten (beispielsweise Name,
            Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit
            möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
            ausdrückliche Zustimmung nicht an Dritte weitergegeben.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
          </p>
          <h3>
            2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
          </h3>
          <p>
            Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
            Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1
            lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
          </p>
          <p>
            Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
            eines Vertrages, dessen Vertragspartei die betroffene Person ist,
            erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als
            Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
            Durchführung vorvertraglicher Maßnahmen erforderlich sind. Soweit
            eine Verarbeitung personenbezogener Daten zur Erfüllung einer
            rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen
            unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
          </p>
          <p>
            Für den Fall, dass lebenswichtige Interessen der betroffenen Person
            oder einer anderen natürlichen Person eine Verarbeitung
            personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1
            lit. d DSGVO als Rechtsgrundlage.
          </p>
          <p>
            Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
            unseres Unternehmens oder eines Dritten erforderlich und überwiegen
            die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
            erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO
            als Rechtsgrundlage für die Verarbeitung.
          </p>
          <h3>3. Datenlöschung und Speicherdauer Die</h3>
          <p>
            personenbezogenen Daten der betroffenen Person werden gelöscht oder
            gesperrt, sobald der Zweck der Speicherung entfällt.
          </p>
          <p>
            Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den
            europäischen oder nationalen Gesetzgeber in unionsrechtlichen
            Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
            Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder
            Löschung der Daten erfolgt auch dann, wenn eine durch die genannten
            Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
            Erforderlichkeit zur weiteren Speicherung der Daten für einen
            Vertragsabschluss oder eine Vertragserfüllung besteht.
          </p>
          <h3>IV. Bereitstellung der Website</h3>
          <p>
            Der Besuch der Website erfolgt anonym. Wir setzen keine Cookies,
            erheben und speichern keine Daten. Jedoch ist eine Kontaktaufnahme
            über die bereitgestellte E-Mail-Adresse möglich. In diesem Fall
            werden die mit der E-Mail übermittelten personenbezogenen Daten des
            Nutzers gespeichert.
          </p>
          <p>
            Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an
            Dritte. Die Daten werden ausschließlich für die Verarbeitung der
            Konversation verwendet.
          </p>
          <h3>V. Rechte der betroffenen Person</h3>
          <p>
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
            Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte
            gegenüber dem Verantwortlichen zu:
          </p>
          <h4>1. Auskunftsrecht</h4>
          <p>
            Sie können von dem Verantwortlichen eine Bestätigung darüber
            verlangen, ob personenbezogene Daten, die Sie betreffen, von uns
            verarbeitet werden.
          </p>
          <p>
            Liegt eine solche Verarbeitung vor, können Sie von dem
            Verantwortlichen über folgende Informationen Auskunft verlangen:
          </p>
          <ul>
            <li>
              die Zwecke, zu denen die personenbezogenen Daten verarbeitet
              werden;
            </li>
            <li>
              die Kategorien von personenbezogenen Daten, welche verarbeitet
              werden;
            </li>
            <li>
              die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen
              die Sie betreffenden personenbezogenen Daten offengelegt wurden
              oder noch offengelegt werden;
            </li>
            <li>
              die geplante Dauer der Speicherung der Sie betreffenden
              personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht
              möglich sind, Kriterien für die Festlegung der Speicherdauer;
            </li>
            <li>
              das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
              betreffenden personenbezogenen Daten, eines Rechts auf
              Einschränkung der Verarbeitung durch den Verantwortlichen oder
              eines Widerspruchsrechts gegen diese Verarbeitung;
            </li>
            <li>
              das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
            </li>
            <li>
              alle verfügbaren Informationen über die Herkunft der Daten, wenn
              die personenbezogenen Daten nicht bei der betroffenen Person
              erhoben werden;
            </li>
            <li>
              das Bestehen einer automatisierten Entscheidungsfindung
              einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und –
              zumindest in diesen Fällen – aussagekräftige Informationen über
              die involvierte Logik sowie die Tragweite und die angestrebten
              Auswirkungen einer derartigen Verarbeitung für die betroffene
              Person.
            </li>
          </ul>
          <p>
            Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
            betreffenden personenbezogenen Daten in ein Drittland oder an eine
            internationale Organisation übermittelt werden. In diesem
            Zusammenhang können Sie verlangen, über die geeigneten Garantien
            gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet
            zu werden.
          </p>
          <p>
            Dieses Auskunftsrecht kann insoweit beschränkt werden, als es
            voraussichtlich die Verwirklichung der Forschungs- oder
            Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und
            die Beschränkung für die Erfüllung der Forschungs- oder
            Statistikzwecke notwendig ist.
          </p>
          <h4>2. Recht auf Berichtigung</h4>
          <p>
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
            gegenüber dem Verantwortlichen, sofern die verarbeiteten
            personenbezogenen Daten, die Sie betreffen, unrichtig oder
            unvollständig sind. Der Verantwortliche hat die Berichtigung
            unverzüglich vorzunehmen.
          </p>
          <p>
            Ihr Recht auf Berichtigung kann insoweit beschränkt werden, als es
            voraussichtlich die Verwirklichung der Forschungs- oder
            Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und
            die Beschränkung für die Erfüllung der Forschungs- oder
            Statistikzwecke notwendig ist.
          </p>
          <h4>3. Recht auf Einschränkung der Verarbeitung</h4>
          <p>
            Unter den folgenden Voraussetzungen können Sie die Einschränkung der
            Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:
          </p>
          <ul>
            <li>
              wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen
              für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht,
              die Richtigkeit der personenbezogenen Daten zu überprüfen;
            </li>
            <li>
              die Verarbeitung unrechtmäßig ist und Sie die Löschung der
              personenbezogenen Daten ablehnen und stattdessen die Einschränkung
              der Nutzung der personenbezogenen Daten verlangen;
            </li>
            <li>
              der Verantwortliche die personenbezogenen Daten für die Zwecke der
              Verarbeitung nicht länger benötigt, Sie diese jedoch zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              benötigen, oder
            </li>
            <li>
              wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
              DSGVO eingelegt haben und noch nicht feststeht, ob die
              berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen
              überwiegen.
            </li>
          </ul>
          <p>
            Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
            eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen
            – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
            anderen natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Union oder eines
            Mitgliedstaats verarbeitet werden.
          </p>
          <p>
            Wurde die Einschränkung der Verarbeitung nach den o.g.
            Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen
            unterrichtet bevor die Einschränkung aufgehoben wird.
          </p>
          <p>
            Ihr Recht auf Einschränkung der Verarbeitung kann insoweit
            beschränkt werden, als es voraussichtlich die Verwirklichung der
            Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft
            beeinträchtigt und die Beschränkung für die Erfüllung der
            Forschungs- oder Statistikzwecke notwendig ist.
          </p>
          <h4>4. Recht auf Löschung</h4>
          <ol type="a">
            <li>
              <h5>Löschungspflicht</h5>
              <p>
                Sie können von dem Verantwortlichen verlangen, dass die Sie
                betreffenden personenbezogenen Daten unverzüglich gelöscht
                werden, und der Verantwortliche ist verpflichtet, diese Daten
                unverzüglich zu löschen, sofern einer der folgenden Gründe
                zutrifft:
              </p>
              <ul>
                <li>
                  Die Sie betreffenden personenbezogenen Daten sind für die
                  Zwecke, für die sie erhoben oder auf sonstige Weise
                  verarbeitet wurden, nicht mehr notwendig.
                </li>
                <li>
                  Sie widerrufen Ihre Einwilligung, auf die sich die
                  Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit.
                  a DSGVO stützte, und es fehlt an einer anderweitigen
                  Rechtsgrundlage für die Verarbeitung.
                </li>
                <li>
                  Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die
                  Verarbeitung ein und es liegen keine vorrangigen berechtigten
                  Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21
                  Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
                </li>
                <li>
                  Die Sie betreffenden personenbezogenen Daten wurden
                  unrechtmäßig verarbeitet.
                </li>
                <li>
                  Die Löschung der Sie betreffenden personenbezogenen Daten ist
                  zur Erfüllung einer rechtlichen Verpflichtung nach dem
                  Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich,
                  dem der Verantwortliche unterliegt.
                </li>
                <li>
                  Die Sie betreffenden personenbezogenen Daten wurden in Bezug
                  auf angebotene Dienste der Informationsgesellschaft gemäß Art.
                  8 Abs. 1 DSGVO erhoben.
                </li>
              </ul>
            </li>
            <li>
              <h5>Information an Dritte</h5>
              <p>
                Hat der Verantwortliche die Sie betreffenden personenbezogenen
                Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu
                deren Löschung verpflichtet, so trifft er unter Berücksichtigung
                der verfügbaren Technologie und der Implementierungskosten
                angemessene Maßnahmen, auch technischer Art, um für die
                Datenverarbeitung Verantwortliche, die die personenbezogenen
                Daten verarbeiten, darüber zu informieren, dass Sie als
                betroffene Person von ihnen die Löschung aller Links zu diesen
                personenbezogenen Daten oder von Kopien oder Replikationen
                dieser personenbezogenen Daten verlangt haben.
              </p>
            </li>
            <li>
              <h5>Ausnahmen</h5>
              <p>
                Das Recht auf Löschung besteht nicht, soweit die Verarbeitung
                erforderlich ist
              </p>
              <ul>
                <li>
                  zur Ausübung des Rechts auf freie Meinungsäußerung und
                  Information;
                </li>
                <li>
                  zur Erfüllung einer rechtlichen Verpflichtung, die die
                  Verarbeitung nach dem Recht der Union oder der
                  Mitgliedstaaten, dem der Verantwortliche unterliegt,
                  erfordert, oder zur Wahrnehmung einer Aufgabe, die im
                  öffentlichen Interesse liegt oder in Ausübung öffentlicher
                  Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
                </li>
                <li>
                  aus Gründen des öffentlichen Interesses im Bereich der
                  öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie
                  Art. 9 Abs. 3 DSGVO;
                </li>
                <li>
                  für im öffentlichen Interesse liegende Archivzwecke,
                  wissenschaftliche oder historische Forschungszwecke oder für
                  statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das
                  unter Abschnitt a) genannte Recht voraussichtlich die
                  Verwirklichung der Ziele dieser Verarbeitung unmöglich macht
                  oder ernsthaft beeinträchtigt, oder
                </li>
                <li>
                  zur Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen.
                </li>
              </ul>
            </li>
          </ol>
          <h4>5. Recht auf Unterrichtung</h4>
          <p>
            Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung
            der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist
            dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
            personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
            Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen,
            es sei denn, dies erweist sich als unmöglich oder ist mit einem
            unverhältnismäßigen Aufwand verbunden.
          </p>
          <p>
            Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese
            Empfänger unterrichtet zu werden.
          </p>
          <h4>6. Recht auf Datenübertragbarkeit</h4>
          <p>
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
            die Sie dem Verantwortlichen bereitgestellt haben, in einem
            strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
            Außerdem haben Sie das Recht diese Daten einem anderen
            Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
            die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
            sofern
          </p>
          <ul>
            <li>
              die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a
              DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem.
              Art. 6 Abs. 1 lit. b DSGVO beruht und
            </li>
            <li>
              die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
            </li>
          </ul>
          <p>
            In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken,
            dass die Sie betreffenden personenbezogenen Daten direkt von einem
            Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
            soweit dies technisch machbar ist. Freiheiten und Rechte anderer
            Personen dürfen hierdurch nicht beeinträchtigt werden.
          </p>
          <p>
            Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung
            personenbezogener Daten, die für die Wahrnehmung einer Aufgabe
            erforderlich ist, die im öffentlichen Interesse liegt oder in
            Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
            übertragen wurde.
          </p>
          <h4>7. Widerspruchsrecht</h4>
          <p>
            Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung der Sie
            betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1
            lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch
            für ein auf diese Bestimmungen gestütztes Profiling.
          </p>
          <p>
            Der Verantwortliche verarbeitet die Sie betreffenden
            personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
            schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
            Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
            dient der Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.
          </p>
          <p>
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
            Widerspruch gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
            dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht.
          </p>
          <p>
            Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so
            werden die Sie betreffenden personenbezogenen Daten nicht mehr für
            diese Zwecke verarbeitet.
          </p>
          <p>
            Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von
            Diensten der Informationsgesellschaft – ungeachtet der Richtlinie
            2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren
            auszuüben, bei denen technische Spezifikationen verwendet werden.
          </p>
          <p>
            Sie haben auch das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, bei der Verarbeitung Sie betreffender
            personenbezogener Daten, die zu wissenschaftlichen oder historischen
            Forschungszwecken oder zu statistischen Zwecken gem. Art. 89 Abs. 1
            DSGVO erfolgt, dieser zu widersprechen.
          </p>
          <p>
            Ihr Widerspruchsrecht kann insoweit beschränkt werden, als es
            voraussichtlich die Verwirklichung der Forschungs- oder
            Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt und
            die Beschränkung für die Erfüllung der Forschungs- oder
            Statistikzwecke notwendig ist.
          </p>
          <h4>
            8. Recht auf Widerruf der datenschutzrechtlichen
            Einwilligungserklärung
          </h4>
          <p>
            Sie haben das Recht, Ihre datenschutzrechtliche
            Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf
            der Einwilligung wird die Rechtmäßigkeit der aufgrund der
            Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
          </p>
          <h4>
            9. Automatisierte Entscheidung im Einzelfall einschließlich
            Profiling
          </h4>
          <p>
            Sie haben das Recht, nicht einer ausschließlich auf einer
            automatisierten Verarbeitung – einschließlich Profiling – beruhenden
            Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
            Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
            beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
          </p>
          <ol type="1">
            <li>
              für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen
              und dem Verantwortlichen erforderlich ist,
            </li>
            <li>
              aufgrund von Rechtsvorschriften der Union oder der
              Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig
              ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung
              Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen
              enthalten oder
            </li>
            <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
          </ol>
          <p>
            Allerdings dürfen diese Entscheidungen nicht auf besonderen
            Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen,
            sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene
            Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer
            berechtigten Interessen getroffen wurden.
          </p>
          <p>
            Hinsichtlich der in (1) und (3) genannten Fälle trifft der
            Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten
            sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das
            Recht auf Erwirkung des Eingreifens einer Person seitens des
            Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
            Anfechtung der Entscheidung gehört.
          </p>
          <h4>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
            einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
            Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der
            Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
          </p>
          <p>
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
            unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse
            der Beschwerde einschließlich der Möglichkeit eines gerichtlichen
            Rechtsbehelfs nach Art. 78 DSGVO.
          </p>

          <Sponsors />
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
